import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { THEME_CONFIG } from './theme-config';
import TranslationEN from '../assets/locale/en/translation.json';

const resources = {
  en: {
    translation: TranslationEN,
  },
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    lng: localStorage.getItem('i18nextLng') || THEME_CONFIG.locale,
    supportedLngs: [
      'aa',
      'ab',
      'ae',
      'af',
      'ak',
      'am',
      'an',
      'ar',
      'as',
      'av',
      'ay',
      'az',
      'az',
      'ba',
      'be',
      'bg',
      'bh',
      'bi',
      'bm',
      'bn',
      'bo',
      'br',
      'bs',
      'ca',
      'ce',
      'ch',
      'co',
      'cr',
      'cs',
      'cu',
      'cv',
      'cy',
      'da',
      'de',
      'dv',
      'dz',
      'ee',
      'el',
      'en',
      'eo',
      'es',
      'et',
      'eu',
      'fa',
      'ff',
      'fi',
      'fj',
      'fo',
      'fr',
      'fy',
      'ga',
      'gd',
      'gl',
      'gn',
      'gu',
      'gv',
      'ha',
      'he',
      'hi',
      'ho',
      'hr',
      'ht',
      'hu',
      'hy',
      'hz',
      'ia',
      'id',
      'ie',
      'ig',
      'ii',
      'ik',
      'io',
      'is',
      'it',
      'iu',
      'ja',
      'jv',
      'ka',
      'kg',
      'ki',
      'kj',
      'kk',
      'kl',
      'km',
      'kn',
      'ko',
      'kr',
      'ks',
      'ku',
      'kv',
      'kw',
      'ky',
      'la',
      'lb',
      'lg',
      'li',
      'ln',
      'lo',
      'lt',
      'lu',
      'lv',
      'mg',
      'mh',
      'mi',
      'mk',
      'ml',
      'mn',
      'mr',
      'ms',
      'mt',
      'my',
      'na',
      'nb',
      'nd',
      'ne',
      'ng',
      'nl',
      'nn',
      'no',
      'nr',
      'nv',
      'ny',
      'oc',
      'oj',
      'om',
      'or',
      'os',
      'pa',
      'pi',
      'pl',
      'ps',
      'pt',
      'qu',
      'rm',
      'rn',
      'ro',
      'ru',
      'rw',
      'sa',
      'sc',
      'sd',
      'se',
      'sg',
      'si',
      'sk',
      'sl',
      'sm',
      'sn',
      'so',
      'sq',
      'sr',
      'ss',
      'st',
      'su',
      'sv',
      'sw',
      'ta',
      'te',
      'tg',
      'th',
      'ti',
      'tk',
      'tl',
      'tn',
      'to',
      'tr',
      'ts',
      'tt',
      'tw',
      'ty',
      'ug',
      'uk',
      'ur',
      'uz',
      've',
      'vi',
      'vo',
      'wa',
      'wo',
      'xh',
      'yi',
      'yo',
      'za',
      'zh',
      'zu',
    ],
    ns: ['translation', 'errors'],
    defaultNS: 'translation',
  });

export default i18n;
